export const GET_EXAM_TYPES_LOADING = "examTypes/GET_EXAM_TYPES_LOADING";
export const GET_EXAM_TYPES_SUCCESS = "examTypes/GET_EXAM_TYPES_SUCCESS";
export const GET_EXAM_TYPES_FAIL = "examTypes/GET_EXAM_TYPES_FAIL";
export const GET_EXAM_TYPES_SUCCESS_EMPTY =
  "examTypes/GET_EXAM_TYPES_SUCCESS_EMPTY";

export const CREATE_EXAM_TYPES_LOADING = "examTypes/CREATE_EXAM_TYPES_LOADING";
export const CREATE_EXAM_TYPES_SUCCESS = "examTypes/CREATE_EXAM_TYPES_SUCCESS";
export const CREATE_EXAM_TYPES_FAIL = "examTypes/CREATE_EXAM_TYPES_FAIL";
export const CREATE_EXAM_TYPES_RESET = "examTypes/CREATE_EXAM_TYPES_RESET";

export const UPDATE_EXAM_TYPES_LOADING = "examTypes/UPDATE_EXAM_TYPES_LOADING";
export const UPDATE_EXAM_TYPES_SUCCESS = "examTypes/UPDATE_EXAM_TYPES_SUCCESS";
export const UPDATE_EXAM_TYPES_FAIL = "examTypes/UPDATE_EXAM_TYPES_FAIL";
export const UPDATE_EXAM_TYPES_RESET = "examTypes/UPDATE_EXAM_TYPES_RESET";

export const DELETE_EXAM_TYPES_LOADING = "examTypes/DELETE_EXAM_TYPES_LOADING";
export const DELETE_EXAM_TYPES_SUCCESS = "examTypes/DELETE_EXAM_TYPES_SUCCESS";
export const DELETE_EXAM_TYPES_FAIL = "examTypes/DELETE_EXAM_TYPES_FAIL";
export const DELETE_EXAM_TYPES_RESET = "examTypes/DELETE_EXAM_TYPES_RESET";
