export const GET_LAYOUTS_LOADING = "layouts/GET_LAYOUTS_LOADING";
export const GET_LAYOUTS_SUCCESS = "layouts/GET_LAYOUTS_SUCCESS";
export const GET_LAYOUTS_SUCCESS_EMPTY = "layouts/GET_LAYOUTS_SUCCESS_EMPTY";
export const GET_LAYOUTS_FAIL = "layouts/GET_LAYOUTS_FAIL";
export const GET_LAYOUTS_RESET = "layouts/GET_LAYOUTS_RESET";

export const SAVE_LAYOUTS_LOADING = "layouts/SAVE_LAYOUTS_LOADING";
export const SAVE_LAYOUTS_SUCCESS = "layouts/SAVE_LAYOUTS_SUCCESS";
export const SAVE_LAYOUTS_FAIL = "layouts/SAVE_LAYOUTS_FAIL";
export const SAVE_LAYOUTS_RESET = "layouts/SAVE_LAYOUTS_RESET";

export const RESET_LAYOUTS_LOADING = "layouts/RESET_LAYOUTS_LOADING";
export const RESET_LAYOUTS_SUCCESS = "layouts/RESET_LAYOUTS_SUCCESS";
export const RESET_LAYOUTS_FAIL = "layouts/RESET_LAYOUTS_FAIL";
export const RESET_LAYOUTS_RESET = "layouts/RESET_LAYOUTS_RESET";

export const SET_LAYOUTS_BREAKPOINT = "layouts/SET_LAYOUTS_BREAKPOINT";
export const RESET_LAYOUTS_BREAKPOINT = "layouts/RESET_LAYOUTS_BREAKPOINT";
