export const SET_AUTHENTICATION_LOADING = "main/SET_AUTHENTICATION_LOADING";
export const SET_AUTHENTICATION_SUCCESS = "main/SET_AUTHENTICATION_SUCCESS";
export const SET_AUTHENTICATION_FAIL = "main/SET_AUTHENTICATION_FAIL";

export const SET_LOGOUT_LOADING = "main/SET_LOGOUT_LOADING";
export const SET_LOGOUT_SUCCESS = "main/SET_LOGOUT_SUCCESS";
export const SET_LOGOUT_FAIL = "main/SET_LOGOUT_FAIL";

export const SET_FORGOT_PASSWORD_LOADING = "main/SET_FORGOT_PASSWORD_LOADING";
export const SET_FORGOT_PASSWORD_SUCCESS = "main/SET_FORGOT_PASSWORD_SUCCESS";
export const SET_FORGOT_PASSWORD_FAIL = "main/SET_FORGOT_PASSWORD_FAIL";

export const GET_USER_SETTINGS_LOADING = "main/GET_USER_SETTINGS_LOADING";
export const GET_USER_SETTINGS_SUCCESS = "main/GET_USER_SETTINGS_SUCCESS";
export const GET_USER_SETTINGS_FAIL = "main/GET_USER_SETTINGS_FAIL";

export const RESET_FORGOT_PASSWORD = "main/RESET_FORGOT_PASSWORD";

export const RESET_APP_STATE = "main/RESET_APP_STATE";
