export const CREATE_ADMISSION_LOADING = "admissions/CREATE_ADMISSION_LOADING";
export const CREATE_ADMISSION_SUCCESS = "admissions/CREATE_ADMISSION_SUCCESS";
export const CREATE_ADMISSION_FAIL = "admissions/CREATE_ADMISSION_FAIL";
export const CREATE_ADMISSION_RESET = "admissions/CREATE_ADMISSION_RESET";

export const UPDATE_ADMISSION_LOADING = "admissions/UPDATE_ADMISSION_LOADING";
export const UPDATE_ADMISSION_SUCCESS = "admissions/UPDATE_ADMISSION_SUCCESS";
export const UPDATE_ADMISSION_FAIL = "admissions/UPDATE_ADMISSION_FAIL";
export const UPDATE_ADMISSION_RESET = "admissions/UPDATE_ADMISSION_RESET";

export const GET_ADMISSION_LOADING = "admissions/GET_ADMISSION_LOADING";
export const GET_ADMISSION_SUCCESS = "admissions/GET_ADMISSION_SUCCESS";
export const GET_ADMISSION_FAIL = "admissions/GET_ADMISSION_FAIL";
export const GET_ADMISSION_SUCCESS_EMPTY =
  "admissions/GET_ADMISSION_SUCCESS_EMPTY";
export const GET_ADMISSION_RESET = "admissions/GET_ADMISSION_RESET";
export const GET_CURRENTADMISSION_LOADING =
  "admissions/GET_CURRENTADMISSION_LOADING";
export const GET_CURRENTADMISSION_SUCCESS =
  "admissions/GET_CURRENTADMISSION_SUCCESS";
export const GET_CURRENTADMISSION_EMPTY =
  "admissions/GET_CURRENTADMISSION_EMPTY";
export const GET_CURRENTADMISSION_FAIL = "admissions/GET_CURRENTADMISSION_FAIL";
export const GET_CURRENTADMISSION_RESET =
  "admissions/GET_CURRENTADMISSION_RESET";

export const DISCHARGE_PATIENT_LOADING = "admissions/DISCHARGE_PATIENT_LOADING";
export const DISCHARGE_PATIENT_SUCCESS = "admissions/DISCHARGE_PATIENT_SUCCESS";
export const DISCHARGE_PATIENT_FAIL = "admissions/DISCHARGE_PATIENT_FAIL";
export const DISCHARGE_PATIENT_RESET = "admissions/DISCHARGE_PATIENT_RESET";

export const GET_ADMISSIONS_LOADING = "admissions/GET_ADMISSIONS_LOADING";
export const GET_ADMISSIONS_SUCCESS = "admissions/GET_ADMISSIONS_SUCCESS";
export const GET_ADMISSIONS_FAIL = "admissions/GET_ADMISSIONS_FAIL";
export const GET_ADMISSIONS_SUCCESS_EMPTY =
  "admissions/GET_ADMISSIONS_SUCCESS_EMPTY";
export const GET_ADMISSIONS_RESET = "admissions/GET_ADMISSIONS_RESET";

export const GET_DISCHARGES_LOADING = "admissions/GET_DISCHARGES_LOADING";
export const GET_DISCHARGES_SUCCESS = "admissions/GET_DISCHARGES_SUCCESS";
export const GET_DISCHARGES_FAIL = "admissions/GET_DISCHARGES_FAIL";
export const GET_DISCHARGES_SUCCESS_EMPTY =
  "admissions/GET_DISCHARGES_SUCCESS_EMPTY";
export const GET_DISCHARGES_RESET = "admissions/GET_DISCHARGES_RESET";

export const GET_PATIENT_ADMISSIONS_LOADING =
  "admissions/GET_PATIENT_ADMISSIONS_LOADING";
export const GET_PATIENT_ADMISSIONS_SUCCESS =
  "admissions/GET_PATIENT_ADMISSIONS_SUCCESS";
export const GET_PATIENT_ADMISSIONS_FAIL =
  "admissions/GET_PATIENT_ADMISSIONS_FAIL";
export const GET_PATIENT_ADMISSIONS_SUCCESS_EMPTY =
  "admissions/GET_PATIENT_ADMISSIONS_SUCCESS_EMPTY";
export const GET_PATIENT_ADMISSIONS_RESET =
  "admissions/GET_PATIENT_ADMISSIONS_RESET";

export const GET_ADMITTED_PATIENTS_LOADING =
  "admissions/GET_ADMITTED_PATIENTS_LOADING";
export const GET_ADMITTED_PATIENTS_SUCCESS =
  "admissions/GET_ADMITTED_PATIENTS_SUCCESS";
export const GET_ADMITTED_PATIENTS_FAIL =
  "admissions/GET_ADMITTED_PATIENTS_FAIL";
export const GET_ADMITTED_PATIENTS_SUCCESS_EMPTY =
  "admissions/GET_ADMITTED_PATIENTS_SUCCESS_EMPTY";
export const GET_ADMITTED_PATIENTS_RESET =
  "admissions/GET_ADMITTED_PATIENTS_RESET";
