export const GET_VACCINES_LOADING = "vaccines/GET_VACCINES_LOADING";
export const GET_VACCINES_SUCCESS = "vaccines/GET_VACCINES_SUCCESS";
export const GET_VACCINES_FAIL = "vaccines/GET_VACCINES_FAIL";

export const CREATE_VACCINE_LOADING = "vaccines/CREATE_VACCINE_LOADING";
export const CREATE_VACCINE_SUCCESS = "vaccines/CREATE_VACCINE_SUCCESS";
export const CREATE_VACCINE_FAIL = "vaccines/CREATE_VACCINE_FAIL";
export const CREATE_VACCINE_RESET = "vaccines/CREATE_VACCINE_RESET";

export const UPDATE_VACCINE_LOADING = "vaccines/UPDATE_VACCINE_LOADING";
export const UPDATE_VACCINE_SUCCESS = "vaccines/UPDATE_VACCINE_SUCCESS";
export const UPDATE_VACCINE_FAIL = "vaccines/UPDATE_VACCINE_FAIL";
export const UPDATE_VACCINE_RESET = "vaccines/UPDATE_VACCINE_RESET";

export const DELETE_VACCINE_LOADING = "vaccines/DELETE_VACCINE_LOADING";
export const DELETE_VACCINE_SUCCESS = "vaccines/DELETE_VACCINE_SUCCESS";
export const DELETE_VACCINE_FAIL = "vaccines/DELETE_VACCINE_FAIL";
export const DELETE_VACCINE_RESET = "vaccines/DELETE_VACCINE_RESET";
