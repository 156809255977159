export const GET_SUPPLIERS_LOADING = "suppliers/GET_SUPPLIERS_LOADING";
export const GET_SUPPLIERS_SUCCESS = "suppliers/GET_SUPPLIERS_SUCCESS";
export const GET_SUPPLIERS_FAIL = "suppliers/GET_SUPPLIERS_FAIL";

export const CREATE_SUPPLIER_LOADING = "suppliers/CREATE_SUPPLIER_LOADING";
export const CREATE_SUPPLIER_SUCCESS = "suppliers/CREATE_SUPPLIER_SUCCESS";
export const CREATE_SUPPLIER_FAIL = "suppliers/CREATE_SUPPLIER_FAIL";
export const CREATE_SUPPLIER_RESET = "suppliers/CREATE_SUPPLIER_RESET";

export const UPDATE_SUPPLIER_LOADING = "suppliers/UPDATE_SUPPLIER_LOADING";
export const UPDATE_SUPPLIER_SUCCESS = "suppliers/UPDATE_SUPPLIER_SUCCESS";
export const UPDATE_SUPPLIER_FAIL = "suppliers/UPDATE_SUPPLIER_FAIL";
export const UPDATE_SUPPLIER_RESET = "suppliers/UPDATE_SUPPLIER_RESET";
