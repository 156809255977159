export const GET_OPERATION_TYPES_LOADING =
  "operationTypes/GET_OPERATION_TYPES_LOADING";
export const GET_OPERATION_TYPES_SUCCESS =
  "operationTypes/GET_OPERATION_TYPES_SUCCESS";
export const GET_OPERATION_TYPES_FAIL =
  "operationTypes/GET_OPERATION_TYPES_FAIL";
export const GET_OPERATION_TYPES_SUCCESS_EMPTY =
  "operationTypes/GET_OPERATION_TYPES_SUCCESS_EMPTY";

export const CREATE_OPERATION_TYPES_LOADING =
  "operationTypes/CREATE_OPERATION_TYPES_LOADING";
export const CREATE_OPERATION_TYPES_SUCCESS =
  "operationTypes/CREATE_OPERATION_TYPES_SUCCESS";
export const CREATE_OPERATION_TYPES_FAIL =
  "operationTypes/CREATE_OPERATION_TYPES_FAIL";
export const CREATE_OPERATION_TYPES_RESET =
  "operationTypes/CREATE_OPERATION_TYPES_RESET";

export const UPDATE_OPERATION_TYPES_LOADING =
  "operationTypes/UPDATE_OPERATION_TYPES_LOADING";
export const UPDATE_OPERATION_TYPES_SUCCESS =
  "operationTypes/UPDATE_OPERATION_TYPES_SUCCESS";
export const UPDATE_OPERATION_TYPES_FAIL =
  "operationTypes/UPDATE_OPERATION_TYPES_FAIL";
export const UPDATE_OPERATION_TYPES_RESET =
  "operationTypes/UPDATE_OPERATION_TYPES_RESET";

export const DELETE_OPERATION_TYPES_LOADING =
  "operationTypes/DELETE_OPERATION_TYPES_LOADING";
export const DELETE_OPERATION_TYPES_SUCCESS =
  "operationTypes/DELETE_OPERATION_TYPES_SUCCESS";
export const DELETE_OPERATION_TYPES_FAIL =
  "operationTypes/DELETE_OPERATION_TYPES_FAIL";
export const DELETE_OPERATION_TYPES_RESET =
  "operationTypes/DELETE_OPERATION_TYPES_RESET";
