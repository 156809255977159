export const GET_DELIVERY_TYPES_LOADING =
  "deliveryTypes/GET_DELIVERY_TYPES_LOADING";
export const GET_DELIVERY_TYPES_SUCCESS =
  "deliveryTypes/GET_DELIVERY_TYPES_SUCCESS";
export const GET_DELIVERY_TYPES_FAIL = "deliveryTypes/GET_DELIVERY_TYPES_FAIL";
export const GET_DELIVERY_TYPES_SUCCESS_EMPTY =
  "deliveryTypes/GET_DELIVERY_TYPES_SUCCESS_EMPTY";

export const CREATE_DELIVERY_TYPES_LOADING =
  "deliveryTypes/CREATE_DELIVERY_TYPES_LOADING";
export const CREATE_DELIVERY_TYPES_SUCCESS =
  "deliveryTypes/CREATE_DELIVERY_TYPES_SUCCESS";
export const CREATE_DELIVERY_TYPES_FAIL =
  "deliveryTypes/CREATE_DELIVERY_TYPES_FAIL";
export const CREATE_DELIVERY_TYPES_RESET =
  "deliveryTypes/CREATE_DELIVERY_TYPES_RESET";

export const UPDATE_DELIVERY_TYPES_LOADING =
  "deliveryTypes/UPDATE_DELIVERY_TYPES_LOADING";
export const UPDATE_DELIVERY_TYPES_SUCCESS =
  "deliveryTypes/UPDATE_DELIVERY_TYPES_SUCCESS";
export const UPDATE_DELIVERY_TYPES_FAIL =
  "deliveryTypes/UPDATE_DELIVERY_TYPES_FAIL";
export const UPDATE_DELIVERY_TYPES_RESET =
  "deliveryTypes/UPDATE_DELIVERY_TYPES_RESET";

export const DELETE_DELIVERY_TYPES_LOADING =
  "deliveryTypes/DELETE_DELIVERY_TYPES_LOADING";
export const DELETE_DELIVERY_TYPES_SUCCESS =
  "deliveryTypes/DELETE_DELIVERY_TYPES_SUCCESS";
export const DELETE_DELIVERY_TYPES_FAIL =
  "deliveryTypes/DELETE_DELIVERY_TYPES_FAIL";
export const DELETE_DELIVERY_TYPES_RESET =
  "deliveryTypes/DELETE_DELIVERY_TYPES_RESET";
