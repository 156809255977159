export const GET_ADMISSION_TYPES_LOADING =
  "admissionTypes/GET_ADMISSION_TYPES_LOADING";
export const GET_ADMISSION_TYPES_SUCCESS =
  "admissionTypes/GET_ADMISSION_TYPES_SUCCESS";
export const GET_ADMISSION_TYPES_FAIL =
  "admissionTypes/GET_ADMISSION_TYPES_FAIL";
export const GET_ADMISSION_TYPES_SUCCESS_EMPTY =
  "admissionTypes/GET_ADMISSION_TYPES_SUCCESS_EMPTY";

export const CREATE_ADMISSION_TYPES_LOADING =
  "admissionTypes/CREATE_ADMISSION_TYPES_LOADING";
export const CREATE_ADMISSION_TYPES_SUCCESS =
  "admissionTypes/CREATE_ADMISSION_TYPES_SUCCESS";
export const CREATE_ADMISSION_TYPES_FAIL =
  "admissionTypes/CREATE_ADMISSION_TYPES_FAIL";
export const CREATE_ADMISSION_TYPES_RESET =
  "admissionTypes/CREATE_ADMISSION_TYPES_RESET";

export const UPDATE_ADMISSION_TYPES_LOADING =
  "admissionTypes/UPDATE_ADMISSION_TYPES_LOADING";
export const UPDATE_ADMISSION_TYPES_SUCCESS =
  "admissionTypes/UPDATE_ADMISSION_TYPES_SUCCESS";
export const UPDATE_ADMISSION_TYPES_FAIL =
  "admissionTypes/UPDATE_ADMISSION_TYPES_FAIL";
export const UPDATE_ADMISSION_TYPES_RESET =
  "admissionTypes/UPDATE_ADMISSION_TYPES_RESET";

export const DELETE_ADMISSION_TYPES_LOADING =
  "admissionTypes/DELETE_ADMISSION_TYPES_LOADING";
export const DELETE_ADMISSION_TYPES_SUCCESS =
  "admissionTypes/DELETE_ADMISSION_TYPES_SUCCESS";
export const DELETE_ADMISSION_TYPES_FAIL =
  "admissionTypes/DELETE_ADMISSION_TYPES_FAIL";
export const DELETE_ADMISSION_TYPES_RESET =
  "admissionTypes/DELETE_ADMISSION_TYPES_RESET";
