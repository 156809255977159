export const GET_WARD_LOADING = "wards/GET_WARD_LOADING";
export const GET_WARD_SUCCESS = "wards/GET_WARD_SUCCESS";
export const GET_WARD_FAIL = "wards/GET_WARD_FAIL";

export const CREATE_WARD_LOADING = "wards/CREATE_WARD_LOADING";
export const CREATE_WARD_SUCCESS = "wards/CREATE_WARD_SUCCESS";
export const CREATE_WARD_FAIL = "wards/CREATE_WARD_FAIL";
export const CREATE_WARD_RESET = "wards/CREATE_WARD_RESET";

export const UPDATE_WARD_LOADING = "wards/UPDATE_WARD_LOADING";
export const UPDATE_WARD_SUCCESS = "wards/UPDATE_WARD_SUCCESS";
export const UPDATE_WARD_FAIL = "wards/UPDATE_WARD_FAIL";
export const UPDATE_WARD_RESET = "wards/UPDATE_WARD_RESET";

export const DELETE_WARD_LOADING = "wards/DELETE_WARD_LOADING";
export const DELETE_WARD_SUCCESS = "wards/DELETE_WARD_SUCCESS";
export const DELETE_WARD_FAIL = "wards/DELETE_WARD_FAIL";
export const DELETE_WARD_RESET = "wards/DELETE_WARD_RESET";
