export const GET_DISEASE_LOADING = "diseases/GET_DISEASE_LOADING";
export const GET_DISEASE_SUCCESS = "diseases/GET_DISEASE_SUCCESS";
export const GET_DISEASE_FAIL = "diseases/GET_DISEASE_FAIL";

export const GET_DISEASEIPDIN_LOADING = "diseases/GET_DISEASEIPDIN_LOADING";
export const GET_DISEASEIPDIN_SUCCESS = "diseases/GET_DISEASEIPDIN_SUCCESS";
export const GET_DISEASEIPDIN_FAIL = "diseases/GET_DISEASEIPDIN_FAIL";

export const GET_DISEASEIPDOUT_LOADING = "diseases/GET_DISEASEIPDOUT_LOADING";
export const GET_DISEASEIPDOUT_SUCCESS = "diseases/GET_DISEASEIPDOUT_SUCCESS";
export const GET_DISEASEIPDOUT_FAIL = "diseases/GET_DISEASEIPDOUT_FAIL";

export const GET_DISEASES_LOADING = "diseases/GET_DISEASES_LOADING";
export const GET_DISEASES_SUCCESS = "diseases/GET_DISEASES_SUCCESS";
export const GET_DISEASES_FAIL = "diseases/GET_DISEASES_FAIL";

export const CREATE_DISEASE_LOADING = "diseases/CREATE_DISEASE_LOADING";
export const CREATE_DISEASE_SUCCESS = "diseases/CREATE_DISEASE_SUCCESS";
export const CREATE_DISEASE_FAIL = "diseases/CREATE_DISEASE_FAIL";
export const CREATE_DISEASE_RESET = "diseases/CREATE_DISEASE_RESET";

export const UPDATE_DISEASE_LOADING = "diseases/UPDATE_DISEASE_LOADING";
export const UPDATE_DISEASE_SUCCESS = "diseases/UPDATE_DISEASE_SUCCESS";
export const UPDATE_DISEASE_FAIL = "diseases/UPDATE_DISEASE_FAIL";
export const UPDATE_DISEASE_RESET = "diseases/UPDATE_DISEASE_RESET";
