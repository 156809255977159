export const GET_MEDICAL_TYPES_LOADING =
  "medicalTypes/GET_MEDICAL_TYPES_LOADING";
export const GET_MEDICAL_TYPES_SUCCESS =
  "medicalTypes/GET_MEDICAL_TYPES_SUCCESS";
export const GET_MEDICAL_TYPES_FAIL = "medicalTypes/GET_MEDICAL_TYPES_FAIL";
export const GET_MEDICAL_TYPES_SUCCESS_EMPTY =
  "medicalTypes/GET_MEDICAL_TYPES_SUCCESS_EMPTY";

export const CREATE_MEDICAL_TYPES_LOADING =
  "medicalTypes/CREATE_MEDICAL_TYPES_LOADING";
export const CREATE_MEDICAL_TYPES_SUCCESS =
  "medicalTypes/CREATE_MEDICAL_TYPES_SUCCESS";
export const CREATE_MEDICAL_TYPES_FAIL =
  "medicalTypes/CREATE_MEDICAL_TYPES_FAIL";
export const CREATE_MEDICAL_TYPES_RESET =
  "medicalTypes/CREATE_MEDICAL_TYPES_RESET";

export const UPDATE_MEDICAL_TYPES_LOADING =
  "medicalTypes/UPDATE_MEDICAL_TYPES_LOADING";
export const UPDATE_MEDICAL_TYPES_SUCCESS =
  "medicalTypes/UPDATE_MEDICAL_TYPES_SUCCESS";
export const UPDATE_MEDICAL_TYPES_FAIL =
  "medicalTypes/UPDATE_MEDICAL_TYPES_FAIL";
export const UPDATE_MEDICAL_TYPES_RESET =
  "medicalTypes/UPDATE_MEDICAL_TYPES_RESET";

export const DELETE_MEDICAL_TYPES_LOADING =
  "medicalTypes/DELETE_MEDICAL_TYPES_LOADING";
export const DELETE_MEDICAL_TYPES_SUCCESS =
  "medicalTypes/DELETE_MEDICAL_TYPES_SUCCESS";
export const DELETE_MEDICAL_TYPES_FAIL =
  "medicalTypes/DELETE_MEDICAL_TYPES_FAIL";
export const DELETE_MEDICAL_TYPES_RESET =
  "medicalTypes/DELETE_MEDICAL_TYPES_RESET";
