export const GET_OPERATIONS_LOADING = "operations/GET_OPERATIONS_LOADING";
export const GET_OPERATIONS_SUCCESS = "operations/GET_OPERATIONS_SUCCESS";
export const GET_OPERATIONS_EMPTY = "operations/GET_OPERATIONROWS_EMPTY";
export const GET_OPERATIONS_FAIL = "operations/GET_OPERATIONS_FAIL";
export const GET_OPERATIONS_RESET = "operations/GET_OPERATIONS_RESET";

export const GET_OPERATIONROW_ADM_LOADING =
  "operations/GET_OPERATIONROW_ADM_LOADING";
export const GET_OPERATIONROW_ADM_SUCCESS =
  "operations/GET_OPERATIONROW_ADM_SUCCESS";
export const GET_OPERATIONROW_ADM_EMPTY =
  "operations/GET_OPERATIONROW_ADM_EMPTY";
export const GET_OPERATIONROW_ADM_FAIL = "operations/GET_OPERATIONROW_ADM_FAIL";
export const GET_OPERATIONROW_ADM_RESET =
  "operations/GET_OPERATIONROW_ADM_RESET";

export const CREATE_OPERATIONROW_LOADING =
  "operations/CREATE_OPERATIONROW_LOADING";
export const CREATE_OPERATIONROW_SUCCESS =
  "operations/CREATE_OPERATIONROW_SUCCESS";
export const CREATE_OPERATIONROW_FAIL = "operations/CREATE_OPERATIONROW_FAIL";
export const CREATE_OPERATIONROW_RESET = "operations/CREATE_OPERATIONROW_RESET";

export const UPDATE_OPERATIONROW_LOADING =
  "operations/UPDATE_OPERATIONROW_LOADING";
export const UPDATE_OPERATIONROW_SUCCESS =
  "operations/UPDATE_OPERATIONROW_SUCCESS";
export const UPDATE_OPERATIONROW_FAIL = "operations/UPDATE_OPERATIONROW_FAIL";
export const UPDATE_OPERATIONROW_RESET = "operations/UPDATE_OPERATIONROW_RESET";

export const DELETE_OPERATIONROW_LOADING =
  "operations/DELETE_OPERATIONROW_LOADING";
export const DELETE_OPERATIONROW_SUCCESS =
  "operations/DELETE_OPERATIONROW_SUCCESS";
export const DELETE_OPERATIONROW_FAIL = "operations/DELETE_OPERATIONROW_FAIL";
export const DELETE_OPERATIONROW_RESET = "operations/DELETE_OPERATIONROW_RESET";

export const CREATE_OPERATION_LOADING = "operations/CREATE_OPERATION_LOADING";
export const CREATE_OPERATION_SUCCESS = "operations/CREATE_OPERATION_SUCCESS";
export const CREATE_OPERATION_FAIL = "operations/CREATE_OPERATION_FAIL";
export const CREATE_OPERATION_RESET = "operations/CREATE_OPERATION_RESET";

export const UPDATE_OPERATION_LOADING = "operations/UPDATE_OPERATION_LOADING";
export const UPDATE_OPERATION_SUCCESS = "operations/UPDATE_OPERATION_SUCCESS";
export const UPDATE_OPERATION_FAIL = "operations/UPDATE_OPERATION_FAIL";
export const UPDATE_OPERATION_RESET = "operations/UPDATE_OPERATION_RESET";

export const DELETE_OPERATION_LOADING = "operations/DELETE_OPERATION_LOADING";
export const DELETE_OPERATION_SUCCESS = "operations/DELETE_OPERATION_SUCCESS";
export const DELETE_OPERATION_FAIL = "operations/DELETE_OPERATION_FAIL";
export const DELETE_OPERATION_RESET = "operations/DELETE_OPERATION_RESET";
