export const NEW_BILL_LOADING = "bills/NEW_BILL_LOADING";
export const NEW_BILL_SUCCESS = "bills/NEW_BILL_SUCCESS";
export const NEW_BILL_FAIL = "bills/NEW_BILL_FAIL";
export const NEW_BILL_RESET = "bills/NEW_BILL_RESET";

export const SEARCH_BILL_LOADING = "bills/SEARCH_BILL_LOADING";
export const SEARCH_BILL_SUCCESS = "bills/SEARCH_BILL_SUCCESS";
export const SEARCH_BILL_FAIL = "bills/SEARCH_BILL_FAIL";

export const PENDING_BILL_LOADING = "bills/PENDING_BILL_LOADING";
export const PENDING_BILL_SUCCESS = "bills/PENDING_BILL_SUCCESS";
export const PENDING_BILL_FAIL = "bills/PENDING_BILL_FAIL";

export const GET_BILL_LOADING = "bills/GET_BILL_LOADING";
export const GET_BILL_SUCCESS = "bills/GET_BILL_SUCCESS";
export const GET_BILL_FAIL = "bills/GET_BILL_FAIL";

export const UPDATE_BILL_LOADING = "bills/UPDATE_BILL_LOADING";
export const UPDATE_BILL_SUCCESS = "bills/UPDATE_BILL_SUCCESS";
export const UPDATE_BILL_FAIL = "bills/UPDATE_BILL_FAIL";
export const UPDATE_BILL_RESET = "bills/UPDATE_BILL_RESET";

export const SEARCH_PAYMENTS_LOADING = "bills/SEARCH_PAYMENTS_LOADING";
export const SEARCH_PAYMENTS_SUCCESS = "bills/SEARCH_PAYMENTS_SUCCESS";
export const SEARCH_PAYMENTS_FAIL = "bills/SEARCH_PAYMENTS_FAIL";

export const DELETE_BILL_FAIL = "bills/DELETE_BILL_FAIL";
export const DELETE_BILL_LOADING = "bills/DELETE_BILL_LOADING";
export const DELETE_BILL_SUCCESS = "bills/DELETE_BILL_SUCCESS";

export const DELETE_BILL_RESET = "bills/DELETE_BILL_RESET";

export const EDIT_BILL_LOADING = "bills/EDIT_BILL_LOADING";
export const EDIT_BILL_SUCCESS = "bills/EDIT_BILL_SUCCESS";
export const EDIT_BILL_FAIL = "bills/EDIT_BILL_FAIL";

export const EDIT_BILL_RESET = "bills/EDIT_BILL_RESET";

export const CLOSE_BILL_LOADING = "bills/CLOSE_BILL_LOADING";
export const CLOSE_BILL_SUCCESS = "bills/CLOSE_BILL_SUCCESS";
export const CLOSE_BILL_FAIL = "bills/CLOSE_BILL_FAIL";

export const CLOSE_BILL_RESET = "bills/CLOSE_BILL_RESET";

export const SEARCH_BILLS_BY_YEAR_LOADING =
  "bills/SEARCH_BILLS_BY_YEAR_LOADING";

export const SEARCH_BILLS_BY_YEAR_SUCCESS =
  "bills/SEARCH_BILLS_BY_YEAR_SUCCESS";

export const SEARCH_BILLS_BY_YEAR_FAIL = "bills/SEARCH_BILLS_BY_YEAR_FAIL";
