export const GET_PREGNANT_TREATMENT_TYPES_LOADING =
  "pregnantTreatmentType/GET_PREGNANT_TREATMENT_TYPES_LOADING";
export const GET_PREGNANT_TREATMENT_TYPES_SUCCESS =
  "pregnantTreatmentType/GET_PREGNANT_TREATMENT_TYPES_SUCCESS";
export const GET_PREGNANT_TREATMENT_TYPES_FAIL =
  "pregnantTreatmentType/GET_DISEASE_TYPES_FAIL";
export const GET_PREGNANT_TREATMENT_TYPES_SUCCESS_EMPTY =
  "pregnantTreatmentType/GET_PREGNANT_TREATMENT_TYPES_SUCCESS_EMPTY";

export const CREATE_PREGNANT_TREATMENT_TYPES_LOADING =
  "pregnantTreatmentType/CREATE_PREGNANT_TREATMENT_TYPES_LOADING";
export const CREATE_PREGNANT_TREATMENT_TYPES_SUCCESS =
  "pregnantTreatmentType/CREATE_PREGNANT_TREATMENT_TYPES_SUCCESS";
export const CREATE_PREGNANT_TREATMENT_TYPES_FAIL =
  "pregnantTreatmentType/CREATE_PREGNANT_TREATMENT_TYPES_FAIL";
export const CREATE_PREGNANT_TREATMENT_TYPES_RESET =
  "pregnantTreatmentType/CREATE_PREGNANT_TREATMENT_TYPES_RESET";

export const UPDATE_PREGNANT_TREATMENT_TYPES_LOADING =
  "pregnantTreatmentType/UPDATE_PREGNANT_TREATMENT_TYPES_LOADING";
export const UPDATE_PREGNANT_TREATMENT_TYPES_SUCCESS =
  "pregnantTreatmentType/UPDATE_PREGNANT_TREATMENT_TYPES_SUCCESS";
export const UPDATE_PREGNANT_TREATMENT_TYPES_FAIL =
  "pregnantTreatmentType/UPDATE_PREGNANT_TREATMENT_TYPES_FAIL";
export const UPDATE_PREGNANT_TREATMENT_TYPES_RESET =
  "pregnantTreatmentType/UPDATE_PREGNANT_TREATMENT_TYPES_RESET";

export const DELETE_PREGNANT_TREATMENT_TYPES_LOADING =
  "pregnantTreatmentType/DELETE_PREGNANT_TREATMENT_TYPES_LOADING";
export const DELETE_PREGNANT_TREATMENT_TYPES_SUCCESS =
  "pregnantTreatmentType/DELETE_PREGNANT_TREATMENT_TYPES_SUCCESS";
export const DELETE_PREGNANT_TREATMENT_TYPES_FAIL =
  "pregnantTreatmentType/DELETE_PREGNANT_TREATMENT_TYPES_FAIL";
export const DELETE_PREGNANT_TREATMENT_TYPES_RESET =
  "pregnantTreatmentType/DELETE_PREGNANT_TREATMENT_TYPES_RESET";
